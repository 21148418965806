
import { getRule, RuleTypes } from "@/core/helpers/rules"
import { Classroom } from "@/core/models/Classroom"
import { SchoolClass } from "@/core/models/SchoolClass"
import { SelectModel, SelectSemesterModel } from "@/core/models/SelectModel"
import ApiService from "@/core/services/ApiService"

import { ElForm } from "element-plus"
import { computed, defineComponent, onMounted, ref, toRefs, watch } from "vue"
import { useRouter } from "vue-router"
import swalConfirm from "@/core/helpers/swal-confirm"
import { formattedDate } from "@/core/helpers/dateformat"
import { ServiceHierarchy } from "@/core/models/ServiceHierarchy"

export default defineComponent({
  name: "school-class-component",
  components: {},
  props: ["add", "id", "closed"],
  emits: ["closed", "getlist"],
  setup(props, { emit }) {
    const router = useRouter()
    const { add, id } = toRefs(props)

    const serviceTreeSelectProps = {
      label: "name",
      value: "id",
      children: "childs",
      disabled: data => {
        return !data.isService
      },
    }

    const schoolClassData = ref<SchoolClass>({} as SchoolClass)

    type FormInstance = InstanceType<typeof ElForm>

    const ruleFormRef = ref<FormInstance>()

    const businessList = ref<SelectModel[]>([])
    const branchList = ref<SelectModel[]>([])
    const semesterList = ref<SelectSemesterModel[]>([])
    const timePeriodList = ref<SelectModel[]>([])
    const classRoomList = ref<SelectModel[]>([])
    const advisorList = ref<SelectModel[]>([])
    const serviceList = ref<ServiceHierarchy[]>([])
    const isBusinessSelectLoading = ref(false)
    const isBranchSelectLoading = ref(false)
    const isSemesterSelectLoading = ref(false)
    const isTimePeriodSelectLoading = ref(false)
    const isServiceSelectLoading = ref(false)
    const isClassRoomSelectLoading = ref(false)
    const isAdvisorSelectLoading = ref(false)

    const semesterStartDate = ref()
    const semesterEndDate = ref()

    const rules = ref({
      businessId: getRule(RuleTypes.SELECT, "İşletme"),
      branchId: getRule(RuleTypes.SELECT, "Şube"),
      semesterId: getRule(RuleTypes.SELECT, "Dönem"),
      timePeriodId: getRule(RuleTypes.SELECT, "Zaman"),
      serviceId: getRule(RuleTypes.SELECT, "Satış Türü"),
      classRoomId: getRule(RuleTypes.SELECT, "Derslik"),
      name: getRule(RuleTypes.TEXT50, "Ad"),
      startDate: getRule(RuleTypes.DATE, "Başlangıç"),
      endDate: getRule(RuleTypes.DATE, "Bitiş"),
      description: getRule(RuleTypes.TEXT100, "Açıklama"),
    })

    const getSchoolClass = async id => {
      const { data } = await ApiService.get("school-class/" + id)
      await getSemesterList(data.businessId)
      await updateSelectedSemesterDates(data.semesterId)
      data.startDate = formattedDate(data.startDate)
      data.endDate = formattedDate(data.endDate)
      onBusinessChange(data.businessId)
      getClassRoomList(data.branchId)
      schoolClassData.value = data
    }

    watch(
      () => props.id,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          getSchoolClass(newValue)
        }
      }
    )

    const showDrawer = computed(() => props.id != undefined || props.add == true)

    watch(
      () => props.add,
      newValue => {
        if (newValue) {
          ruleFormRef.value?.resetFields()
          schoolClassData.value = {} as SchoolClass
          getBusinessList()
        }
      }
    )

    const getBranchList = async businessId => {
      isBranchSelectLoading.value = true
      const config = {
        params: {
          businessId: businessId,
        },
      }
      const { data } = await ApiService.query("branch", config)
      branchList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (branchList.value.length == 1) {
        schoolClassData.value.branchId = branchList.value[0].id
      }
      onBranchChange(schoolClassData.value.branchId)
      isBranchSelectLoading.value = false
    }

    const getSemesterList = async businessId => {
      isSemesterSelectLoading.value = true
      const { data } = await ApiService.get("semester/business/" + businessId)
      semesterList.value = data
      if (semesterList.value.length == 1) {
        schoolClassData.value.semesterId = semesterList.value[0].id
        updateSelectedSemesterDates(semesterList.value[0].id)
      }
      isSemesterSelectLoading.value = false
    }

    const getTimePeriodList = async businessId => {
      isTimePeriodSelectLoading.value = true
      const { data } = await ApiService.get("time-period/business/" + businessId)
      timePeriodList.value = data
      if (timePeriodList.value.length == 1) {
        schoolClassData.value.timePeriodId = timePeriodList.value[0].id
      }
      isTimePeriodSelectLoading.value = false
    }

    const getServiceList = async businessId => {
      isServiceSelectLoading.value = true
      const query = {
        params: {
          isWithService: true,
        },
      }
      const { data } = await ApiService.query(
        `service-category/business/${businessId}/childs`,
        query
      )
      serviceList.value = data
      isServiceSelectLoading.value = false
    }

    const getClassRoomList = async branchId => {
      if (branchId != "") {
        isClassRoomSelectLoading.value = true
        const { data } = await ApiService.get("class-room/branch/" + branchId)
        classRoomList.value = data
        isClassRoomSelectLoading.value = false
      }
    }

    const onBusinessChange = async businessId => {
      schoolClassData.value.branchId = ""
      schoolClassData.value.semesterId = ""
      schoolClassData.value.timePeriodId = ""
      schoolClassData.value.serviceId = ""
      schoolClassData.value.classRoomId = ""
      schoolClassData.value.advisorId = ""
      classRoomList.value = [] as Classroom[]
      await getBranchList(businessId)
      await getSemesterList(businessId)
      await getTimePeriodList(businessId)
      await getServiceList(businessId)
    }

    const onBranchChange = async branchId => {
      schoolClassData.value.classRoomId = schoolClassData.value.classRoomId
        ? schoolClassData.value.classRoomId
        : ""
      if (branchId != undefined) await getClassRoomList(branchId)
    }

    const updateSelectedSemesterDates = async selectedSemesterId => {
      if (!selectedSemesterId) return
      const selectedSemester = semesterList.value.find(
        semester => semester.id === selectedSemesterId
      ) as SelectSemesterModel
      if (!selectedSemester) return
      const { startDate, endDate } = selectedSemester
      semesterStartDate.value = formattedDate(startDate)
      semesterEndDate.value = formattedDate(endDate)
      schoolClassData.value.startDate = semesterStartDate.value
      schoolClassData.value.endDate = semesterEndDate.value
    }

    const addSchoolClass = async () => {
      const data = { ...schoolClassData.value }
      await ApiService.post("school-class/add", data)
      emit("getlist")
      router.push({ name: "school-class-list" })
    }
    const updateSchoolClass = async () => {
      const data = { ...schoolClassData.value, id: props.id }
      await ApiService.post("school-class/edit", data)
      emit("getlist")
      router.push({ name: "school-class-list" })
    }

    const isDateInRangeOfSemester = async () => {
      const schoolStartDate = new Date(schoolClassData.value.startDate.toString())
      const schoolEndDate = new Date(schoolClassData.value.endDate.toString())
      const StartDate = new Date(semesterStartDate.value.toString())
      const EndDate = new Date(semesterEndDate.value.toString())

      const isDateOutOfRange = (date, minDate, maxDate) => {
        return date.getTime() < minDate.getTime() || date.getTime() > maxDate.getTime()
      }

      if (
        isDateOutOfRange(schoolEndDate, StartDate, EndDate) ||
        isDateOutOfRange(schoolStartDate, StartDate, EndDate)
      ) {
        const confirmDate = await swalConfirm({
          title: "Dikkat",
          text: "Eklemek istediğiniz sınıfın başlangıç ve bitiş tarihi, içinde bulunduğu dönem için belirlenen tarihin dışındadır. Yine de sınıfı eklemek istiyor musunuz?",
          icon: "warning",
        })
        if (!confirmDate.isConfirmed) {
          return false
        }
      }

      return true
    }

    function addOrUpdate(formEl) {
      if (!formEl) return
      formEl.validate(async valid => {
        if (valid) {
          const isDateInRange = await isDateInRangeOfSemester()
          if (!isDateInRange) {
            return
          }
          if (props.add) {
            addSchoolClass()
          } else {
            updateSchoolClass()
          }
        }
      })
    }

    onMounted(async () => {
      await getBusinessList()
    })

    const getBusinessList = async () => {
      isBusinessSelectLoading.value = true
      const { data } = await ApiService.get("business")
      businessList.value = data.filter(x => x.isActive).map(x => ({ id: x.id, name: x.title }))
      if (businessList.value.length == 1) {
        schoolClassData.value.businessId = businessList.value[0].id
        onBusinessChange(businessList.value[0].id)
      }

      isBusinessSelectLoading.value = false
    }

    return {
      showDrawer,
      newSchoolClass: add,
      activeSchoolClassId: id,
      handleClose: props.closed,
      schoolClassData,
      addOrUpdate,
      ruleFormRef,
      businessList,
      branchList,
      semesterList,
      timePeriodList,
      serviceList,
      classRoomList,
      advisorList,
      onBusinessChange,
      onBranchChange,
      updateSelectedSemesterDates,
      isBusinessSelectLoading,
      isBranchSelectLoading,
      isSemesterSelectLoading,
      isTimePeriodSelectLoading,
      isServiceSelectLoading,
      isClassRoomSelectLoading,
      isAdvisorSelectLoading,
      serviceTreeSelectProps,
      rules,
      semesterStartDate,
      semesterEndDate,
    }
  },
})
